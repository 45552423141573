export default {
    SUCCESS: {
        icon: 'i-heroicons-check-circle-20-solid',
        color: 'primary'
    } as Partial<Notification>,
    ERROR: {
        icon: 'i-heroicons-exclamation-circle-20-solid',
        color: 'red'
    } as Partial<Notification>,
    INFO: {
        icon: 'i-heroicons-information-circle-20-solid',
        color: 'blue'
    } as Partial<Notification>,
}