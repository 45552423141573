import { default as userskUiEMeWhG4Meta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue?macro=true";
import { default as applicationgZ0u44xc1aMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue?macro=true";
import { default as attachments8NVLCFCkqMMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue?macro=true";
import { default as contacto4HiY5uOwbMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue?macro=true";
import { default as prescreen9KxA9gHgJgMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue?macro=true";
import { default as scholarshipE5r0H6RRcrMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue?macro=true";
import { default as summary3EHI5JOnewMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue?macro=true";
import { default as casezsugcC4kH3Meta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case.vue?macro=true";
import { default as case_45assignmentsZXoiM9uxyMMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue?macro=true";
import { default as index4zae9FICueMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/index.vue?macro=true";
import { default as new_45case0v6zpmRYDQMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/new-case.vue?macro=true";
import { default as providerlistddAm137ZB3Meta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue?macro=true";
import { default as eligibilityQWpaYLWHWJMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue?macro=true";
import { default as _91id_934xTOQjHhhCMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue?macro=true";
import { default as searchQef9EQOBcmMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/search.vue?macro=true";
import { default as indexOlH4Y2fOdkMeta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue?macro=true";
import { default as notificationsDjvm9YAEb2Meta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue?macro=true";
import { default as settingsBCNHm0b715Meta } from "/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/settings.vue?macro=true";
export default [
  {
    name: "admin-users",
    path: "/admin/users",
    meta: userskUiEMeWhG4Meta || {},
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "case",
    path: "/case",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case.vue").then(m => m.default || m),
    children: [
  {
    name: "case-id-application",
    path: ":id()/application",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue").then(m => m.default || m)
  },
  {
    name: "case-id-attachments",
    path: ":id()/attachments",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue").then(m => m.default || m)
  },
  {
    name: "case-id-contact",
    path: ":id()/contact",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue").then(m => m.default || m)
  },
  {
    name: "case-id-prescreen",
    path: ":id()/prescreen",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue").then(m => m.default || m)
  },
  {
    name: "case-id-scholarship",
    path: ":id()/scholarship",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue").then(m => m.default || m)
  },
  {
    name: "case-id-summary",
    path: ":id()/summary",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "csp-admin-case-assignments",
    path: "/csp-admin/case-assignments",
    meta: case_45assignmentsZXoiM9uxyMMeta || {},
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "new-case",
    path: "/new-case",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/new-case.vue").then(m => m.default || m)
  },
  {
    name: "providerlist",
    path: "/providerlist",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue").then(m => m.default || m)
  },
  {
    name: "public-csp-eligibility",
    path: "/apply/childcare/eligibility",
    meta: eligibilityQWpaYLWHWJMeta || {},
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue").then(m => m.default || m)
  },
  {
    name: "report-id",
    path: "/report/:id()",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/search.vue").then(m => m.default || m)
  },
  {
    name: settingsBCNHm0b715Meta?.name,
    path: "/settings",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/codebuild/output/src30814273/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue").then(m => m.default || m)
  }
]
  }
]