import { defineNuxtPlugin } from '#app';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth';

export default defineNuxtPlugin(() => {
    const auth = useAuthStore();
    const config = useRuntimeConfig();

    if(config.public.environment === 'local' || config.public.environment === 'dev') {
        console.log('Runtime config:', config.public);
    }

    Amplify.configure({
        Auth: {
            Cognito: {
                //  Amazon Cognito User Pool ID
                userPoolId: config.public.amplify.userPoolId,
                // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                userPoolClientId: config.public.amplify.userPoolClientId,
                // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
                identityPoolId: '',
                allowGuestAccess: false,
            },
        },
    });
    Hub.listen('auth', ({ payload }) => {
        switch (payload.event) {
            case 'signedIn':
                auth.init();
                displayLocalToken();
                break;
            case 'tokenRefresh':
                displayLocalToken();
                break;
        }
        
    });

    async function displayLocalToken() {
        if(config.public.environment === 'local') {
            const token = (await fetchAuthSession()).tokens?.idToken?.toString();
            console.log('Amplify token:', token);
        }
    }
});


