import { fetchAuthSession } from 'aws-amplify/auth';

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();

    const api = $fetch.create({
        baseURL: config.public.apiBaseUrl.replace(/\/$/, ''),
        async onRequest({ request, options }) {
            if(request.toString().startsWith('/public')) {
                // TODO PWDI-305 - Add API token

                // Add captcha token if available
                if(options.recaptcha && options.recaptcha.token) {
                    const headers = (options.headers ||= {});
                    if (Array.isArray(headers)) {
                        headers.push(['X-Wdi-Captcha', `${options.recaptcha.token}`]);
                    } else if (headers instanceof Headers) {
                        headers.set('X-Wdi-Captcha', `${options.recaptcha.token}`);
                    } else {
                        headers['X-Wdi-Captcha'] = `${options.recaptcha.token}`;
                    }
                }
            } else {
                const token = (await fetchAuthSession()).tokens?.idToken?.toString();
                if (token) {
                    const headers = (options.headers ||= {});
                    if (Array.isArray(headers)) {
                        headers.push(['Authorization', `Bearer ${token}`]);
                    } else if (headers instanceof Headers) {
                        headers.set('Authorization', `Bearer ${token}`);
                    } else {
                        headers.Authorization = `Bearer ${token}`;
                    }
                }
            }
        },
    });

    // Expose to useNuxtApp().$api
    return {
        provide: {
            api,
        },
    };
});
