export default defineAppConfig({
    ui: {
        primary: 'green',
        gray: 'cool',
        tooltip: {
            default: {
                openDelay: 500,
            },
        },
        formGroup: {
            wrapper: 'nuxtFormGroup'
        }
    },
});
