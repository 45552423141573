import * as Sentry from '@sentry/nuxt';


export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup() {
        const config = useRuntimeConfig();

        Sentry.init({
            dsn: config.public.sentry.dsnPublic,
            tracesSampleRate: config.public.sentry.tracesSampleRate,
            environment: config.public.environment,
            integrations: [
                Sentry.browserTracingIntegration(),
            ],
            tracePropagationTargets: [config.public.apiBaseUrl]
        });

    },
});
